.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.App-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
}

.aSpan {
  font-size: 14px;
  color: gainsboro;
}

@media (min-width: 650px) {
  .App {
    background-position: center center;
  }

  .App-content {
    justify-content: top;
  }
}
