.jumbotron {
    background-color: #495057;
    border-radius: 20px;
    margin: 10px;
}

.myHeading {
    color: white;
}

.myText {
    color: gainsboro;
}